import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import PostList from '../components/post-list';
import PageHeader from '../components/page-header';
import PageIntro from '../components/page-intro';
import DicedInfo from '../components/diced-info';
import StyledLink from '../components/styled-link';
import styled from 'styled-components';

const HomePage = ({ data }) => {
  const posts = data.featuredDiensten.nodes.concat(data.featuredProjecten.nodes).slice(0, 6);
  const intro = data.intro.html;
  const header = data.header.html;
  const title = data.header.frontmatter.title;

  return (
    <Layout title={title}>
      <PageHeader header>
        <div dangerouslySetInnerHTML={{
          __html: header,
        }}/>
        <div>
          <StyledLink to="/about"
            css={`
              display: block;
              margin-top: var(--size-800);
            `}
          >
           Wie is <strong>Hedaloc</strong> ?
          </StyledLink>
        </div>
      </PageHeader>

      <PageIntro dangerouslySetInnerHTML={{
        __html: intro
      }}/>

      <PostList posts={posts} />
      <StyledLink
        css={`
          display: block;
          margin-top: var(--size-800);
          margin-bottom: var(--size-800);
          margin-left: auto;
          margin-right: auto;
          width: fit-content;
        `}
        to="/projecten"
      >
        Bekijk alle projecten
      </StyledLink>
      <DicedInfo />
    </Layout>
  );
};

export default HomePage;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    featuredDiensten: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "diensten" } }, frontmatter: { featured: { eq: true } } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 6
    ) {
      nodes {
        fields {
          slug
        }
        excerpt
        timeToRead
        frontmatter {
          description
          title
          image
        }
      }
    }
    featuredProjecten: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "projecten" } }, frontmatter: { featured: { eq: true } } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 6
    ) {
      nodes {
        fields {
          slug
        }
        excerpt
        frontmatter {
          description
          title
          image
        }
      }
    }
    header: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
    intro: markdownRemark(fields: { slug: { eq: "/intro/" } }) {
      html
    }
  }
`;
