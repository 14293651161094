import React from 'react';
import styled from 'styled-components';
import { FaCalendar, FaUsers, FaProductHunt, FaTruck, FaPencilRuler, FaCogs } from 'react-icons/fa';
import PageIntro from '../components/page-intro';

const INFO_BODY = [
  {intro: 'jarenlange', body: 'ervaring', icon: FaCalendar},
  {intro: 'gerespecteerd', body: 'familiebedrijf', icon: FaUsers},
  {intro: 'projecten', body: 'klein en groot', icon: FaProductHunt},
  {intro: 'aanleg', body: 'van a tot z', icon: FaTruck},
  {intro: 'prachtig', body: 'tuinontwerp', icon: FaPencilRuler},
  {intro: 'modern', body: 'materieel', icon: FaCogs}
];

const DicedInfo = () => {
  const Dices = INFO_BODY.map((dice, i) => {
    return (
      <InfoDice key={i} dice={dice}/>
    );
  });
  return (
    <div>
      <PageIntro>
        <h2>Waarom Hedaloc?</h2>
      </PageIntro>
      <StyledDices>
        {Dices}
      </StyledDices>
    </div>
  );
};

const InfoDice = ({dice}) => {
  let { intro, body, icon } = dice;
  const Icon = icon;
  return (
    <StyledInfoDice>
      <StyledIcon><Icon size='3em' /></StyledIcon>
      <div>{intro}</div>
      <div>{body}</div>
    </StyledInfoDice>
  )
}

const StyledIcon = styled.div`
  margin-top: var(--size-300);
  margin-bottom: var(--size-300);
`;

const StyledInfoDice = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  border-radius: 8px;
  text-transform: uppercase;
  display: flex;
  flex-grow: 1;
  flex-basis: 220px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: var(--size-300);
`;

const StyledDices = styled.div`
  margin-top: -1rem;
  margin-bottom: var(--size-400);
  display: flex;
  justify-content: space-between;
  gap: var(--size-300);
  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

export default DicedInfo;
